import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { db, auth } from "../config/firebase"; // Adjust the import according to your project structure
import {
  collection,
  query,
  getDocs,
  orderBy as firestoreOrderBy,
  limit,
  startAfter,
  where,
  Timestamp,
} from "firebase/firestore";

const CallLogs = () => {
  const [callLogs, setCallLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("recording_start_time");

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];

      let agentId = localStorage.getItem("agentId");
      if (!agentId) {
        agentId = agentDoc.id;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentId", agentId);
      }

      const callLogsRef = collection(db, `agents/${agentId}/transcribed_calls`);
      const q = query(
        callLogsRef,
        firestoreOrderBy(orderBy, order),
        limit(rowsPerPage)
      );
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCallLogs(data);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    };

    fetchData();
  }, [rowsPerPage, order, orderBy]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    if (newPage > page) {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];

      let agentId = localStorage.getItem("agentId");
      if (!agentId) {
        agentId = agentDoc.id;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentId", agentId);
      }

      const callLogsRef = collection(db, `agents/${agentId}/transcribed_calls`);
      const q = query(
        callLogsRef,
        firestoreOrderBy(orderBy, order),
        startAfter(lastVisible),
        limit(rowsPerPage)
      );
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCallLogs(data);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <h1>Call Logs</h1>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "agent_phone_number"}
                    direction={orderBy === "agent_phone_number" ? order : "asc"}
                    onClick={() => handleRequestSort("agent_phone_number")}
                  >
                    Agent Phone Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "call_sid"}
                    direction={orderBy === "call_sid" ? order : "asc"}
                    onClick={() => handleRequestSort("call_sid")}
                  >
                    Call SID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "lead_first_name"}
                    direction={orderBy === "lead_first_name" ? order : "asc"}
                    onClick={() => handleRequestSort("lead_first_name")}
                  >
                    Lead First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "lead_state"}
                    direction={orderBy === "lead_state" ? order : "asc"}
                    onClick={() => handleRequestSort("lead_state")}
                  >
                    Lead State
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "lead_phone_number"}
                    direction={orderBy === "lead_phone_number" ? order : "asc"}
                    onClick={() => handleRequestSort("lead_phone_number")}
                  >
                    Lead Phone Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "recording_start_time"}
                    direction={
                      orderBy === "recording_start_time" ? order : "asc"
                    }
                    onClick={() => handleRequestSort("recording_start_time")}
                  >
                    Call Start Time
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callLogs.map((callLog) => (
                <TableRow key={callLog.id}>
                  <TableCell>{callLog.agent_phone_number}</TableCell>
                  <TableCell>{callLog.call_sid}</TableCell>
                  <TableCell>{callLog.lead_first_name}</TableCell>
                  <TableCell>{callLog.lead_state}</TableCell>
                  <TableCell>{callLog.lead_phone_number}</TableCell>
                  <TableCell>
                    {callLog.recording_start_time.toDate().toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1} // Unknown number of total rows
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default CallLogs;
