import { createContext, useContext, useEffect, useState } from "react";

/**
 * Create a context to store the agentId and access it throughout
 * the dashboard.
 */
const AgentContext = createContext();

export const AgentProvider = ({ children }) => {
  const [agentId, setAgentId] = useState(null);

  useEffect(() => {
    const storedAgentId = localStorage.getItem("agentId");
    if (storedAgentId) {
      setAgentId(storedAgentId);
    }
  }, []);

  return (
    <AgentContext.Provider value={{ agentId, setAgentId }}>
      {children}
    </AgentContext.Provider>
  );
};

export const useAgent = () => useContext(AgentContext);
