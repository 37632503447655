import React, { useState, useEffect } from "react";
import { auth, db } from "../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Tooltip } from "react-tooltip";

import "../styles/webhook.css";

const Webhook = () => {
  const [agentId, setAgentId] = useState("");

  useEffect(() => {
    const fetchAgentId = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];

      let agentId = localStorage.getItem("agentId");
      if (!agentId) {
        agentId = agentDoc.id;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentId", agentId);
      }

      setAgentId(agentId);
    };

    fetchAgentId();
  }, [auth.currentUser]);

  const copyToClipboard = async (url) => {
    await navigator.clipboard.writeText(url);
    alert("URL copied to clipboard!");
  };

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Webhooks for GHL</h1>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>Twilio call backs</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/handle-call-backs?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/handle-call-backs?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>Medicare Artificial Voice Leads</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/create-lead-med-supp-cc-lt?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/create-lead-med-supp-cc-lt?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>Medicare T65 Facebook</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/create-lead-med-supp-t65-fb-lt?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/create-lead-med-supp-t65-fb-lt?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>Medicare Supplement Facebook</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/create-lead-med-supp-fb-lt?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/create-lead-med-supp-fb-lt?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>MAPD Facebook</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/create-lead-mapd-fb-lt?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/create-lead-mapd-fb-lt?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>Final Expense Facebook</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/create-lead-final-expense-fb-lt?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/create-lead-final-expense-fb-lt?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>Final Expense Artificial Voice Leads</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/create-lead-final-expense-cc-lt?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/create-lead-final-expense-cc-lt?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>ACA Artificial Voice Leads</h3>
        <p
          data-tip="Click to copy"
          onClick={async () =>
            await copyToClipboard(
              `https://agent-autopilot.agentautodialer.com/server/create-lead-aca-cc-lt?agentId=${agentId}`
            )
          }
        >
          https://agent-autopilot.agentautodialer.com/server/create-lead-aca-cc-lt?agentId=
          {agentId}
        </p>
        <Tooltip />
      </div>
    </>
  );
};

export default Webhook;
