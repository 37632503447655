/**
 * Helper function to capitalize the first letter of a string
 *
 * @param {*} string
 * @returns
 */
export const capitalizeFirstLetter = (string) => {
  let lowerCaseString = string.toLowerCase();
  let firstLetter = lowerCaseString.charAt(0);
  let capitalizedFirstLetter = firstLetter.toUpperCase();
  let restOfString = lowerCaseString.slice(1);
  return capitalizedFirstLetter + restOfString;
};
