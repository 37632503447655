import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "./styles/side-panel.css";
import ManageFBLTPhoneCalls from "./pages/manage-fb-lt-calls";
import TestPhoneCalls from "./pages/test-phone-calls";
import logo from "../src/assets/images/logo.png";
import ManageCCLTPhoneCalls from "./pages/manage-cc-lt-calls";
import OnboardAgent from "./pages/onboard-agent";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Login from "./pages/login";
import PrivateRoute from "./pages/private-router";
import Billing from "./pages/billing";
import Home from "./pages/home";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./config/firebase";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "firebase/auth";
import Webhook from "./pages/webhook";
import WebhookIcon from "@mui/icons-material/Webhook";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CallIcon from "@mui/icons-material/Call";
import CallLogs from "./pages/call-logs";
import SettingsPage from "./pages/settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Feedback, Settings } from "@mui/icons-material";
import ManageLeads from "./pages/manage-leads";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { set } from "firebase/database";
import LiveTransfersAndCallBacks from "./pages/live-transfers-and-call-backs";
import { AgentProvider } from "./context/agent-context";
import { SidePanelButton } from "./components/side-panel-button";
import { AgencyAdmin } from "./pages/agency-admin";

function App() {
  const [isPanelOpen, setPanelOpen] = useState(true);
  const [user, loading] = useAuthState(auth);
  const [_loading, setLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState("");
  const [agencyName, setAgencyName] = useState("");

  useEffect(() => {
    const fetchLogoUrl = async () => {
      try {
        const user = auth.currentUser;
        // console.log("User:", user);
        if (!user) return;

        // Fetch the user's document
        const userDocRef = query(
          collection(db, "agents"),
          where("uid", "==", user.uid)
        );
        const userDoc = await getDocs(userDocRef);
        if (!userDoc.docs.length === 0) return;

        const userData = userDoc.docs[0].data();
        // console.log("User data:", userData);
        const agencyRef =
          userData.agency_ref ?? doc(db, "agencies", "idB9m08LPQaM0kDsFXLM");

        // Fetch the agency document
        const agencyDocRef = agencyRef;
        const agencyDoc = await getDoc(agencyDocRef);
        if (!agencyDoc.exists()) return;

        const agencyData = agencyDoc.data();
        const _logoUrl = agencyData.logo_url;

        // console.log("Logo URL:", _logoUrl);

        setLogoUrl(_logoUrl);
        setAgencyName(agencyData.name);
        document.title = `${agencyData.name} Voice AI`;
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = agencyData.favicon_url;
      } catch (error) {
        console.error("Error fetching logo URL:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogoUrl();
  }, [user]);

  if (loading && _loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {isPanelOpen && user != null && (
          // <div
          //   style={{
          //     margin: "8em",
          //   }}
          // >
          //   <center>
          //     <img
          //       src={logoUrl}
          //       // src="https://lifeagentlaunch.com/wp-content/uploads/2024/06/lifeagentlaunchlogo-1.png"
          //       width={"250px"}
          //       style={{
          //         borderRadius: "10%",
          //         backgroundColor: "black",
          //       }}
          //     />
          //     <br />
          //     <br />
          //     <br />
          //     <br />
          //     <h1>
          //       The system is currently under maintenance with ongoing updates
          //       to address recent issues. Please stay posted for an email
          //       notifying when the system is back up and running stably.
          //     </h1>
          //   </center>
          // </div>
          <div className="side-panel">
            <center>
              <img
                src={logoUrl}
                // src="https://lifeagentlaunch.com/wp-content/uploads/2024/06/lifeagentlaunchlogo-1.png"
                width={"100px"}
                style={{
                  borderRadius: "10%",
                  backgroundColor: "black",
                }}
              />
            </center>
            <h1
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "Arial",
                margin: "0 auto",
                padding: "20px",
              }}
            >
              {agencyName} Voice AI
            </h1>
            <ul>
              <li>
                <SidePanelButton Icon={HomeIcon} text="Home" to="/" />
              </li>
              <li>
                <SidePanelButton
                  Icon={ManageAccountsIcon}
                  text="Manage Leads"
                  to="/manage-leads"
                />
              </li>
              <li>
                <SidePanelButton
                  Icon={CallIcon}
                  text="Call Logs"
                  to="/call-logs"
                />
              </li>
              <li>
                <SidePanelButton
                  Icon={PhoneForwardedIcon}
                  text="Live Transfers & Call Backs"
                  to="/live-transfers"
                />
              </li>
              <li>
                <SidePanelButton
                  Icon={AccountBalanceWalletIcon}
                  text="Billing"
                  to="/billing"
                />
              </li>
              <li>
                <SidePanelButton
                  Icon={WebhookIcon}
                  text="Webhooks"
                  to="/webhook"
                />
              </li>
              <li>
                <SidePanelButton
                  Icon={Settings}
                  text="Settings"
                  to="/settings"
                />
              </li>
              <li>
                <SidePanelButton
                  Icon={Feedback}
                  text="Feedback"
                  color={"secondary"}
                  to="https://docs.google.com/forms/d/e/1FAIpQLSc2WKiJ8SLK-61NMake5EOm4dBA2uLTp4bzHjR7xm7OS614pQ/viewform"
                />
              </li>
              <li>
                <SidePanelButton
                  Icon={LogoutIcon}
                  text="Sign Out"
                  color={"primary"}
                  sx={{ backgroundColor: "#333" }}
                  onClick={async () => {
                    localStorage.clear();
                    await signOut(auth);
                  }}
                />
              </li>
            </ul>
          </div>
        )}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/billing"
            element={
              <PrivateRoute>
                <div className="content">
                  <Billing />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/webhook"
            element={
              <PrivateRoute>
                <div className="content">
                  <Webhook />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <div className="content">
                  <AgentProvider>
                    <Home />
                  </AgentProvider>
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-leads"
            element={
              <PrivateRoute>
                <div className="content">
                  <ManageLeads />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/call-logs"
            element={
              <PrivateRoute>
                <div className="content">
                  <CallLogs />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/live-transfers"
            element={
              <PrivateRoute>
                <div className="content">
                  <LiveTransfersAndCallBacks />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <div className="content">
                  <SettingsPage />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/agency-admin"
            element={
              <PrivateRoute>
                <div className="content">
                  <AgencyAdmin />
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
