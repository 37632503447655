import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const SidePanelButton = ({
  Icon,
  text,
  to,
  variant,
  color,
  onClick,
  sx,
}) => {
  return (
    <Button
      component={Link}
      to={to}
      variant={variant ?? "contained"}
      color={color ?? "primary"}
      fullWidth
      onClick={onClick}
      sx={sx ?? { boxShadow: "none" }}
    >
      <Icon />
      <div style={{ width: "5px" }} />
      {text}
    </Button>
  );
};
