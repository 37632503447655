import styled, { keyframes } from "styled-components";

const pulse = keyframes`
0% {
    opacity:0.1;
  }

  50% {
    opacity:0.75;
  }

  100% {
    opacity:0.1;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  column-gap: 20px;
  row-gap: 20px;
  padding: 24px;
`;

const Card = styled.div`
  width: 150px;
  height: 125px;
  background-color: #27292b;
  background-size: 200% 100%;
  border-radius: 8px;
  animation: ${pulse} 2s ease-in-out infinite;
`;

const Typography = styled.div`
  width: 100px;
  height: 20px;
  background-color: #27292b;
  background-size: 200% 100%;
  border-radius: 8px;
  margin-top: 16px;
  animation: ${pulse} 2s linear infinite;
`;

export default function PulseHomePageCard() {
  return (
    <Grid>
      <Card>
        <Typography variant="h7"></Typography>
        <Typography variant="h5"></Typography>
      </Card>
    </Grid>
  );
}
